import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export default class CaseStudyTabs extends React.Component {

  render() {
    return (
      <Tabs>
        <TabList>
          <Tab>Complaint</Tab>
          <Tab>Medication</Tab>
          <Tab>Family</Tab>
          <Tab>Social</Tab>
          <Tab>Physical</Tab>
          
        </TabList>
        
        {/* Complaint Tab */}
        <TabPanel>
          <ul>
            <li>CC: abdominal pain, diarrhea, fecal urgency</li>
            <li>No weight loss</li>
            <li>Hypothyroidism</li>
            <li>Iron-deficiency anemia</li>
            <li>Dysthymia post-partum</li>
            <li>Oral aphthous ulcers</li>
          </ul>
        </TabPanel>

        {/* Medications Tab */}
        <TabPanel>
          <ul>
            <li>levothyroxine 112mcg daily</li>
            <li>FeSO4 325 daily</li>
          </ul>
        </TabPanel>        
        
        {/* Family Tab */}
        <TabPanel>
          <ul>
            <li>Maternal grandfather diagnosed with colon cancer at 76</li>
          </ul>
        </TabPanel>

        {/* Social Tab */}
        <TabPanel>
          <ul>
            <li>Accountant in a high-stress position</li>
            <li>Two-year old daughter at home</li>
            <li>Non-smoker</li>
          </ul>
        </TabPanel>

        {/* Physical Tab  */}
        <TabPanel>
          <ul>
            <li>Ht: 5’6</li>
            <li>Wt: 161</li> 
            <li>BMI: 26 kg/m<sup>2</sup></li>
            <li>BP: 115/68 mm Hg</li>
            <li>Afebrile (98.4⁰F)</li>
            <li>Abdomen: Slight abdominal tenderness not local to any quadrant and has associated rebound or guarding</li>
            <li>CBC Hg 11.5, MCV 80</li>
            <li>TSH 2.2 nl</li>
            <li>Vitamin D 20mg/dl</li>
            <li>Electrolytes and calcium are normal</li>
          </ul>
        </TabPanel>


      </Tabs>
    )
  }
}