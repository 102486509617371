import React from 'react'
import Link from 'gatsby-link'

export default class HomepageVignette extends React.Component {
  render() {
    return (
      <div>
        <section className="aga-hp-vignette-container">
          <section className="aga-hp-vignette-text-container">
            <div className="aga-hp-vignette-number">{ this.props.order }</div>
            <div className="aga-hp-case-study">
              <h3 className="aga-hp-case-study-title">{ this.props.title }</h3>
              <p className="aga-hp-case-study-text">{ this.props.summary }</p>
            </div>
          </section>  
          <Link to={ this.props.link } className="aga-button-regular orange-bg aga-hp-download-button">Begin Case Study</Link>
        </section>
      </div>
    )
  }
}