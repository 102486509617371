import React from 'react'

import Layout from '../../components/Layout'
import HomepageVignette from '../../components/HomepageVignette';
import CaseStudyIntro from '../../components/CaseStudyIntro';

export default class CasesPage extends React.Component {

  // Handles fade-in by detecing when the component fully mounts, then doing fade-in
  componentDidMount() {
    document.getElementById('aga-case-anchor').classList.add('aga-fade-in')
  }

  render() {
    return (
      <div>
        <Layout>
          <div id="aga-case-anchor">
            <CaseStudyIntro />
            <section className="lightest-gray-bg aga-hp-case-study-container">
              <section className="aga-container">
                <h2 className="aga-hp-vignette-section-title">Begin the case study sequence</h2>
                <HomepageVignette
                  order="1"
                  title="Diagnosis – Primary Care"
                  summary="Jennifer has a BMI of 26 and says she has been heavier than she would like since her pregnancy. She mentions that she was diagnosed with mild anemia postpartum, and that her obstetrician prescribed FeSO4 (525mg daily). She is afebrile, and her physical exam is unremarkable except for a slight abdominal tenderness that does not localize to any quadrant and has no associated rebound or guarding."
                  link="/cases/primary-care-diagnosis"
                />
                <HomepageVignette
                  order="2"
                  title="Medication Management - Gastroenterology"
                  summary="You examine Jennifer after she is referred to you by her primary-care physician. After reviewing her history and labs, you order an MRI and find mild thickening of the last 15 centimeters of her terminal ileum. These imaging findings lead Jennifer to worry that she has colon cancer. You assure her that the findings could be due to a number of causes and that a colonoscopy will provide the opportunity to obtain tissue samples of the affected area to make a diagnosis."
                  link="/cases/medication-management-gastroenterology"
                />        
                <HomepageVignette
                  order="3"
                  title="Health Maintenance – Primary Care"
                  summary="About a year after her initial Crohn’s diagnosis, Jennifer returns to you, her primary care physician, for a regular health maintenance visit. Her gastroenterologist recently started her on biologic therapy for her Crohn’s Disease. She feels relatively well on the new therapy and mentions that she and her husband are starting to think about having another baby."
                  link="/cases/health-maintenance-primary-care"
                />    
              </section>
            </section>
          </div>
        </Layout>
      </div>

    )
  }
}
