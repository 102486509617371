import React from 'react'

import CaseStudyWoman from '../../components/CaseStudyIntro/case-study-lady.jpg'
import CaseStudyObjectives from '../../components/CaseStudyObjectives'
import CaseStudyTabs from '../../components/CaseStudyTabs'


export default class CaseStudyIntro extends React.Component {

  // Handles fade-in by detecing when the component fully mounts, then doing fade-in
  componentDidMount() {
    document.getElementById('aga-cs-intro').classList.add('aga-fade-in')
  }

  render() {
    return (
      <div>        
        <section className="aga-container" id="aga-cs-intro">
          <article className="aga-cs-main-content aga-two-wide">
            <section className="aga-cs-tall-image">
              <img src={ CaseStudyWoman } alt="Jennifer, the IBD patient" />
            </section>
            <section className="aga-cs-two-wide-content">
              <h1 className="aga-cs-patient-title">Meet Jennifer</h1>
              <h3 className="aga-cs-patient-subtitle">30 Year Old Female</h3>
              <CaseStudyTabs />
            </section>
          </article>
        </section>
        <CaseStudyObjectives />
      </div>
    )
  }
}