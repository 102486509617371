import React from 'react'

export default class CaseStudyObjectives extends React.Component {
  render() {
    return (
      <div>        
  
        <section className="midnight-blue-bg aga-cs-container">
          <h2 className="aga-darkblock-title">Learning Objectives</h2>
          <div className="aga-three-stack">
            <div className="aga-single-item">Recognize when patient referral to a gastroenterologist from primary care is appropriate and timely</div>
            <div className="aga-single-item">Describe safety and efficacy of current and emerging medications for ulcerative colitis and Crohn’s disease</div>
            <div className="aga-single-item">Describe strategies for modifying treatment regimens for symptom relief and to achieve remission</div>
          </div>
        </section>  

      </div>
    )
  }
}